.dropdown {
  display: inline-block;
  outline: none;
  position: relative;

  &:hover {
    outline: none;
  }

  &:active,
  &:focus {
    outline: none;
  }

  &:invalid {
    border: 1px solid hsl(358, 75%, 49%);

    &:hover,
    &:focus {
      box-shadow: 0 0.25rem 1rem 0 hsl(356, 43%, 82%);
    }
  }
}

.dropdownToggle {
  color: #000;
  height: unset;
  /* padding: 5px; */
  cursor: pointer;
  padding: 5px 10px;
  background-color: transparent;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  /* text-align: left; */
  font-size: 16px;
  min-width: 165px;
  border: 1px solid #92929280;
  border-radius: 4px;
  transition: all 200ms ease;
  height: 30px;
  box-shadow: initial;
  text-indent: 2px;
  background-color: #fff;

  &:hover {
    box-shadow: 0 0.25rem 1rem 0 rgba(121, 50, 154, 0.8);
    border: 1px solid rgba(121, 50, 154, 0.33);
    outline: none;
  }

  &:active,
  &:focus {
    box-shadow: 0 0.25rem 1rem 0 rgba(121, 50, 154, 0.8);
    border: 1px solid #79329a;
    outline: none;
  }

  &:invalid {
    border: 1px solid hsl(358, 75%, 49%);

    &:hover,
    &:focus {
      box-shadow: 0 0.25rem 1rem 0 hsl(356, 43%, 82%);
    }
  }
}

.thick {
  height: 40px;
}

.dropdownToggle.opened {
  box-shadow: 0 0.25rem 1rem 0 rgba(121, 50, 154, 0.66);
  border: 1px solid #79329a;
  outline: none;
}

.dropdownMenu {
  display: block;
  background-color: #f9f9f9;
  min-width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 0;
  position: absolute;
  z-index: 10000;
  margin: 5px 0 0;
  max-height: 230%;
  overflow-y: scroll;
  width: 100%;
}

.dropdownItem {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.dropdownItem:hover {
  background-color: #f1f1f1;
}

.arrowIcon {
  width: 15px;
  transition: 0.3s;
}

.rotate180 {
  transform: rotate(180deg);
}

.fWidthDropDown {
  flex: 1;
  width: 100%;
}
