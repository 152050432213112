.container {
  max-width: 1200px;
  position: relative;
}

.main {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: #79329a;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0.25rem 1rem 0 rgba(121, 50, 154, 0.33);
  cursor: pointer;

  @media print {
    display: none;
  }
}

.icon {
  width: 20px;
}
