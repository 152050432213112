.flexBtns {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
}
.flexRow {
  display: flex;
  gap: 15px;
}
.userListItem {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.layoutMsg {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.maxWidth {
  max-width: 150px;
}

.customFileInput {
  /* display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 8px;
  margin: 0;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid #ccc; */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0 20px;
  border-radius: 8px;
  border: 0.125rem solid transparent;
  font-weight: 600;
  line-height: 1;
  cursor: pointer;
  box-shadow: 0 0.25rem 1rem 0 rgba(121, 50, 154, 0.33);
  transition: 200ms;
  height: 40px;
  text-decoration: none;
  font-size: 14px;
  background-color: #79329a;
  color: #fff;

  img {
    width: 22px;
  }
}
.layoutBtns {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 220px;
}
.alignCenter {
  text-align: center;
  width: 300px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.namesStyle {
  background: none;
  list-style: none;
  padding: 10px;
  border-radius: 10px;
  border: 2px solid #fff;
  width: 100px;
  margin: 10px 0;
  text-align: center;
}

.ulStyle {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  max-width: 350px;
  margin: 50px auto;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 10px;
}

.ulStyle::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.ulStyle::-webkit-scrollbar-track {
  background: #f1f1f1;
  background: none;
  margin: 10px 0;
}

.ulStyle::-webkit-scrollbar-thumb {
  background: #79329a;
  border-radius: 6px;
  margin: 4px;
}
