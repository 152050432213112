.profileContainer {
  min-height: 100vh;
  padding-bottom: 20px;
}

.profile {
  margin: 20px auto;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 800px;

  @media (min-width: 576px) {
    margin: 40px auto;
  }
}

.profileCard {
  margin: 40px 15px;
  @media (min-width: 576px) {
    margin: 40px auto;
    /* max-width: 80%; */
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #cb5b52; */
  background-color: #eee;
  /* color: #fff; */
  padding: 20px;
  border-radius: 16px 16px 0 0;
  @media (min-width: 576px) {
    padding: 40px;
  }
}

.profileGreeting {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.profileGreeting span {
  font-size: 32px;
  color: var(--main-purple-color);
  /* text-align: right; */
  padding-right: 10px;
}

.profileGreeting .name {
  font-size: 20px;
  color: #000;
  /* text-align: right; */
  padding-right: 10px;
}

.avatar {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  position: absolute;
  top: -40px;
  left: 20px;
  box-shadow: 0 0 8px #2103408f;

  @media (min-width: 576px) {
    left: 40px;
  }
}

.avatarRtl {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  position: absolute;
  top: -40px;
  left: 20px;
  box-shadow: 0 0 8px #2103408f;

  @media (min-width: 576px) {
    right: 40px;
  }
}

.coverImage {
  width: 180px;
}

.content {
  background-color: #fff;
  position: relative;
  box-shadow: 0 0 20px #ccc;
  padding: 60px 0 40px;
  border-radius: 0 0 16px 16px;
}

.profileActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  position: absolute;
  padding: 0 10px;
  top: 20px;
  right: 0;
  @media (min-width: 576px) {
    padding: 0 30px;
  }
}

.profileActionsRtl {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  position: absolute;
  padding: 0 10px;
  top: 20px;
  left: 0;
  @media (min-width: 576px) {
    padding: 0 30px;
  }
}

.contentRow {
  padding: 10px 20px;

  @media (min-width: 576px) {
    padding: 10px 40px;
  }
}

.contentRow h3 {
  color: #888;
  font-size: 15px;
}

.readingMode {
  border-bottom: 1px solid #ccc;
}

.editingMode {
  padding-bottom: 10px;
}

.readingMode p {
  font-size: 14px;
}

.readingMode .infoTitle {
  color: #888;
}

.changePasswordSection {
}

.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin: 20px 0;

  input {
    width: 100%;
    padding: 10px;
    margin: 0;
  }
}

.inputLabel {
  font-size: 14px;
  color: #888;
}

.birthContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0;

  @media (min-width: 576px) {
    flex-direction: row;
  }
}

.selectBox {
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: 220px;
  justify-content: space-between;

  @media (min-width: 576px) {
    max-width: unset;
  }

  select {
    padding: 10px;
    border-radius: 4px;
    flex: 1;
    max-width: 150px;
    border-color: transparent;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  }
}

.fullSelectBox {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin: 20px 0;

  select {
    padding: 10px;
    border-radius: 4px;
    flex: 1;
    border-color: transparent;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    width: 100%;
  }
}

.contentRow .primaryButton {
  margin: 5px 0 16px;
}

.contentRow .deleteAccountButton {
  margin: 5px 0;
  background-color: #cb5b52 !important;
  color: white;
}

.contentRow .passwordActions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
  margin: 20px 0;
}

.contentRow .link {
  text-decoration: none;
  color: #000;
  transition: 0.3s;
}

.contentRow .link:hover {
  color: goldenrod;
}

.modalHeading {
  font-size: 20px;
  margin-bottom: 0;
}

.btnGroup {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.trip {
  text-decoration: none;
}

.flexbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flexbox button {
  margin: 0;
}

.columnFlexbox {
  display: flex;
  flex-direction: column;
  gap: 5px 30px;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}
