.sliderContainer {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  border-radius: 8px;

  @media print {
    display: none;
  }
}

.pdfSliderHorizontal {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  white-space: nowrap;
  gap: 15px;
  margin-bottom: 20px;
}

.pdfSliderVertical {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  white-space: nowrap;
  padding: 10px;
  gap: 15px;
}

.sliderImage {
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.sliderImage:hover {
  transform: scale(1.02);
}

.sliderImage img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
}

.actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin: 10px 0;
  padding: 10px 0;
}

.overlay {
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  padding: 0 40px;
  overflow-y: hidden;
  max-height: 95vh;
}

.modalHeader {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}

.close {
  color: white;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
}

.modalFooter {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}

.modalBody {
  max-width: 800px;
  overflow-y: auto;
}

.modalContent {
  max-width: 100%;
  max-height: 100%;
}

.document {
  display: flex;
  flex-direction: column;
}

.fileName {
  width: 200px;
  object-fit: cover;
  word-break: break-word;
  white-space: normal;
  text-align: center;
}

.printingSliderImage {
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.printingSliderImage img {
  width: 100%;
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
}
