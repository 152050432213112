.inputContainer {
  flex: 1;
}

.textInput {
  color: #000;
  height: unset;
  padding: 5px 10px;
  width: 100%;
  border: 1px solid #92929280;
  border-radius: 4px;
  transition: box-shadow 200ms ease;
  /* height: 40px; */
  box-shadow: initial;
  text-indent: 2px;
  background-color: #fff;
  height: 30px;

  &:hover {
    box-shadow: 0 0.25rem 1rem 0 rgba(121, 50, 154, 0.33);
    border: 1px solid rgba(121, 50, 154, 0.33);
    outline: none;
  }

  &:active,
  &:focus {
    box-shadow: 0 0.25rem 1rem 0 rgba(121, 50, 154, 0.66);
    border: 1px solid #79329a;
    outline: none;
  }

  &:invalid {
    border: 1px solid hsl(358, 75%, 49%);

    &:hover,
    &:focus {
      box-shadow: 0 0.25rem 1rem 0 hsl(356, 43%, 82%);
    }
  }
}

/* Specific styles for textarea if needed */
textarea.textInput {
  resize: vertical; /* Allow vertical resizing only */
  min-height: 100px; /* Set a minimum height */
}

.thick {
  height: 40px;
}

.textInput:focus,
.textInput:hover {
  border-radius: 4px;
}
