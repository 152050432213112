.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 6px;

  input {
    width: 100%;
    padding: 10px;
    margin: 0;
  }
}

.inputLabel {
  font-size: 14px;
  color: #4c4c4c;
}


.centeredError {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}