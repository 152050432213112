.card {
    margin: 40px 0;
    border-radius: 16px;
  }
  
  .content {
    background-color: #fff;
    position: relative;
    box-shadow: 0 0 20px #ccc;
    padding: 30px 20px;
    border-radius: 16px;
    transition: 0.3s;
  
    @media (min-width: 576px) {
      padding: 30px 40px;
    }
  }
  
  .collapsed .content {
    max-height: 140px;
    padding: 10px 20px;
  
    @media (min-width: 576px) {
      padding: 20px 40px;
    }
  }
  
  .card .cardHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .card .cardTitle {
    font-size: 24px;
    font-weight: 500;
    margin: 0;
  }
  
  .card .collapseButton {
    margin: 0;
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: transparent !important;
    font-weight: 500;
  }
  
  .card .collapseButton img {
    width: 20px;
    transition: 0.3s;
  }
  
  .collapsed .collapseButton img {
    transform: rotate(180deg);
  }
  
  .card .cardContent {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin: 20px 0;
  }
  
  .card .cardContent h4 {
    font-size: 16px;
    font-weight: 500;
    color: #4d4d4d;
    margin: 0;
  }
  
  /* .card .cardContent p */
  .cardContent p {
    margin: 0;
    color: #4d4d4d;
    font-size: 14px;
  }
  
  .card .centeredFlexbox {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .card .showMoreButton {
    min-width: 30%;
    max-width: 400px;
    margin: 0 auto;
    padding: 15px;
    font-weight: 500;
  }
  