.errorBox {
  display: flex;
  width: fit-content;
  padding: 10px 20px;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin: 20px auto;
}

.errorBox p {
  margin: 0;
  color: #000;
  font-size: 20px;
}

.errorBox img {
  width: 80px;
}
