/* .viewInvoicePage {
  background-color: red;
} */

.container {
  max-height: 1000px;
  margin: 0 auto;
  padding: 10px;
}

.section {
  /* background-color: #f5f5f5; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
}

@media print {
  .section {
    display: flex;
    background-color: f5f5f5 !important;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
  .invoiceContainer {
    height: 100vh;
  }
}

.section .col {
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (min-width: 576px) {
    flex-direction: row;
    align-items: center;
  }
}

.section .row {
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: hidden;
}

.section .boldText {
  font-weight: bold;
}

.section .breakWord {
  word-break: break-all;
}

.actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin: 10px 0;
  padding: 10px 0;
}

.invoiceContainer {
  padding: 40px 0;
  /* height: 100vh; */
}

/* .inputContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
} */

.fileInput {
  display: none;
}

.inputContainer label {
  width: 100%;
}

.customFileInput {
  /* display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 8px;
  margin: 0;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid #ccc; */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0 20px;
  border-radius: 8px;
  border: 0.125rem solid transparent;
  font-weight: 600;
  line-height: 1;
  cursor: pointer;
  box-shadow: 0 0.25rem 1rem 0 rgba(121, 50, 154, 0.33);
  transition: 200ms;
  height: 40px;
  text-decoration: none;
  font-size: 14px;
  background-color: #79329a;
  color: #fff;

  img {
    width: 22px;
  }
}

.fileName {
  display: block;
  margin: 5px;
  font-size: 14px;
}

.noDocuments {
  color: gray;
  font-size: 14px;
}

.pdfUploaderContainer {
  display: flex;
  flex-direction: column;
}

.pdfUploaderSection {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 8px;
}

.pdfUploaderSectionActions {
  display: flex;
  flex-direction: column;
  width: fit-content;
}
