.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: 1.5rem;
  color: #333;
  background-color: transparent;
  border-radius: 5px;
  position: relative;
  z-index: 10;
  margin-bottom: 40px;
}

.fullScreenHeight {
  height: 100vh;
}

.loaderText {
  margin-bottom: 10px;
  margin-top: 120px;
  font-size: 16px;
}

.loader::after {
  content: "";
  border: 4px solid #f3f3f3;
  /* Light gray border */
  border-top: 4px solid #79329a;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  position: absolute;
  animation: spin 1s linear infinite;
  /* Rotation animation */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
