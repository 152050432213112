.viewInvoicePage {
  /* background-color: #f5f5f5; */
  min-height: 100vh;
}

.user {
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;

  @media (min-width: 576px) {
    flex-direction: row;
    align-items: center;
  }
}

.userTitle {
  min-width: 100px;
  padding: 0;
  margin: 0;
}

.userName {
  font-weight: 600;
  padding: 0;
  margin: 0;
}

.container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 10px 20px;
}

.section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
  margin: 10px 0;
  padding: 10px 15px;
  /* padding: 10px 20px; */
  border-radius: 8px;
}

.sectionSpecial {
  display: flex;
  max-width: 600px;
  gap: 15px;
  margin: 10px 0;
  padding: 10px 15px;
  border-radius: 8px;
}

.sectionSpecialCol {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;

  @media (min-width: 576px) {
    flex-direction: row;
    align-items: center;
  }
}

.sectionSpecialColText {
  font-weight: 600;
}

@media print {
  .section {
    background-color: f5f5f5 !important;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
  /* .invoiceContainer {
    height: 100vh;
  } */
}

.section .col {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;

  @media (min-width: 576px) {
    flex-direction: row;
    align-items: center;
  }
}

.invoiceDetailsTitle {
  border-bottom: 2px solid #ccc;
  padding-bottom: 10px;
  font-size: 18px;
}

.colTitle {
  min-width: 100px;
}

.section .row {
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: hidden;
}

.section .boldText {
  font-weight: bold;
}

.section .breakWord {
  word-break: break-all;
}

.actions {
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
  margin: 10px 0 60px;
  padding: 10px 0;

  @media print {
    display: none;
  }

  @media (min-width: 576px) {
    margin: 10px 0 10px;
  }
}

.invoiceContainer {
  padding: 40px 0;
  /* height: 100vh; */
}

/* .inputContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
} */

.fileInput {
  display: none;
}

.inputContainer label {
  width: 100%;
}

.customFileInput {
  /* display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 8px;
  margin: 0;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid #ccc; */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0 20px;
  border-radius: 8px;
  border: 0.125rem solid transparent;
  font-weight: 600;
  line-height: 1;
  cursor: pointer;
  box-shadow: 0 0.25rem 1rem 0 rgba(121, 50, 154, 0.33);
  transition: 200ms;
  height: 40px;
  text-decoration: none;
  font-size: 14px;
  /* min-width: 100px; */
  background-color: #79329a;
  color: #fff;

  img {
    width: 22px;
  }
}

.fileName {
  display: block;
  margin: 5px;
  font-size: 14px;
}

.archiveModalBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.archiveModalBodyTitle {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 500;
}

.archiveModalInvoiceNumber {
  margin: 10px 0;
  padding: 0;
  font-size: 15px;
  font-weight: 600;
  color: #79329a;
}
