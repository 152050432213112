.forgetPasswordTextButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: transparent;
    border: none;
    font-size: 16px;
    margin-top: 10px;
    color: #79329a;
}

.modalTitle {
    font-size: 26px;
    text-align: center;
  }
  
  .modalSubTitle {
    font-size: 16px;
    color: #4c4c4c;
    line-height: 1.6;
    text-align: center;
    font-weight: 400;
  }
  
  .inputContainer {
    display: flex;
    flex-direction: column;
    gap: 6px;
  
    input {
      width: 100%;
      padding: 10px;
      margin: 0;
    }
  }