* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  height: 100%;
  width: 100%;
}

:root {
  scroll-behavior: smooth;
  height: 100%;
  width: 100%;
}

body {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
}

@media (max-width: 576px) {
  .mobile-hider {
    display: none;
  }
}

.website-logo {
  width: 150px;
  display: block;

  // @media (min-width: 576px) {
  //   // width: 150px;
  //   margin: unset;
  // }
}

.not-found {
  color: gray;
  font-size: 14px;
}

.page-title {
  font-size: 23px;
}

/* Banner styles */
.o-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.o-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.invoice-banner {
  background-color: #f5f5f5;
  padding-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
}

.invoice-banner .header-container {
  max-width: 1000px;
  margin: 0 auto;
  direction: rtl;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #79329a;
  color: #fff;
  padding: 15px 0;
  text-align: center;
  font-size: 1.5em;
  font-weight: bold;
  // border-radius: 10px;
  @media print {
    display: none;
  }

  .logo {
    width: 100px;

    @media (min-width: 576px) {
      width: 120px;
    }
  }
}

.invoice-banner .user-actions {
  display: flex;
  column-gap: 20px;
  align-items: center;
}

.invoice-banner .user-actions img {
  width: 28px;
  cursor: pointer;
  border-radius: 50%;
  transition: 0.3s;
}

.invoice-banner .user-actions img:hover {
  box-shadow: 0 0.25rem 1rem 0 rgba(121, 50, 154, 0.33);
  transform: scale(1.1);
}

.c-button {
  display: block;
  background-color: white;
  border-radius: 8px;
  border: 0.125rem solid transparent;
  color: #79329a;
  font-weight: 600;
  line-height: 1;
  cursor: pointer;
  box-shadow: 0 0.25rem 1rem 0 rgba(121, 50, 154, 0.33);
  transition: box-shadow 200ms ease;
  transition: border-color 200ms ease;
  height: 40px;

  &:hover {
    box-shadow: 0 0.25rem 1rem 0 rgba(121, 50, 154, 0.33);
    border: 1px solid rgba(121, 50, 154, 0.33);
    outline: none;
  }

  &:active,
  &:focus {
    box-shadow: 0 0.25rem 1rem 0 rgba(121, 50, 154, 0.66);
    border: 1px solid #79329a;
    outline: none;
  }

  &:invalid {
    border: 1px solid hsl(358, 75%, 49%);

    &:hover,
    &:focus {
      box-shadow: 0 0.25rem 1rem 0 hsl(356, 43%, 82%);
    }
  }
}

.c-button.navigation-button {
  background-color: purple;
  color: #fff;
  // margin-left: auto;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 0 20px;
  justify-self: flex-end;
  width: fit-content;
}

.entry {
  margin-top: 0.5rem;
}

.entry-item {
  display: grid !important;
  grid-template-areas:
    "error"
    "field"
    "helper-text";
  align-items: start;
}

.entry-item-label {
  font-size: 0.75rem;
  font-weight: 400;
  grid-area: field;
  position: relative;
  margin-top: 0.4rem;
  margin-left: 1rem;
}

input {
  color: hsl(0, 0%, 0%);
  border: 0.063rem solid hsl(0, 0%, 57.2%);
  border-radius: clamp(0.5rem, 0.8vw, 0.75rem);
  transition: box-shadow 200ms ease;
  height: 40px;

  &:hover {
    box-shadow: 0 0.25rem 1rem 0 rgba(121, 50, 154, 0.33);
    border: 1px solid rgba(121, 50, 154, 0.33);
    outline: none;
  }

  &:active,
  &:focus {
    box-shadow: 0 0.25rem 1rem 0 rgba(121, 50, 154, 0.66);
    border: 1px solid #79329a;
    outline: none;
  }

  &:invalid {
    border: 1px solid hsl(358, 75%, 49%);

    &:hover,
    &:focus {
      box-shadow: 0 0.25rem 1rem 0 hsl(356, 43%, 82%);
    }
  }
}

.landing-input-feild {
  box-sizing: border-box;
  display: block;
  padding-top: 1.5rem;
  padding-right: 1rem;
  padding-bottom: 0.7rem;
  padding-left: 1rem;
  // background-color: var(--color-neutral--light);
  // border: 0.063rem solid var(--color-neutral--dark);
  color: black;
  border-radius: 0.5rem;
  width: 100%;
  transition: 0 0.25rem 1rem 0 hsl(356, 43%, 82%) 200ms ease;
  font-size: 1rem;
  font-weight: 600;
  // font-family: var(--font);
  grid-area: field;
  height: 50px !important;
}

.onboarding {
  display: flex;
  justify-content: center;
  // height: 100vh;
  box-sizing: border-box;
  overflow-x: auto;

  padding-bottom: clamp(1.5rem, 3vw, 2.5rem);

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-font-variant-ligatures: normal;
  font-variant-ligatures: normal;
  -moz-font-feature-settings: "kern=1";
  -webkit-font-feature-settings: "kern";
  -moz-font-feature-settings: "kern";
  font-feature-settings: "kern";
  font-kerning: normal;
  font-weight: 400;
  line-height: 1.5;
  color: hsl(0, 0%, 0%);
  // background-image: url(https://partnerportal.sida.se/b2c/login_background.svg);
  // background-image: url("../assets/login_background.svg");
  // background-position: center bottom;
  // background-repeat: no-repeat;
  // background-size: cover;
}

.onboarding-container {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-image: url("../assets/login_background.svg");
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

.users-title-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;
}

.users-title-section .title {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
}

.main-wrapper {
  display: flex;
  flex-direction: row;
  gap: 50px;
  justify-content: space-between;
  padding-top: clamp(2rem, 6vw, 9.375rem);
  padding-left: clamp(1.5rem, 3vw, 2.5rem);
  padding-right: clamp(1.5rem, 3vw, 2.5rem);
  height: unset;
  max-width: 1000px;
  align-items: flex-start;
}

#login-form {
  height: auto;
}

.tabs {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  border-bottom: 2px solid #79329a;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  margin-right: 5px;
  font-size: 18px;
  font-weight: 600;
  color: gray;
}

.tab.active {
  color: #79329a;
}

.rigister-login-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: clamp(28.125rem, 30vw, 28.75rem);
  background: white;
  box-shadow: 0 0.75rem 3rem rgba(0, 0, 0, 0.33);
  border-radius: 1rem;
  padding: clamp(1.25rem, 4.6%, 1.5rem);
  height: auto;
  max-height: auto;
  overflow-y: auto;

  h2 {
    margin-block-start: 0.1em !important;
    margin-block-end: 0.1em !important;
  }

  input {
    color: hsl(0, 0%, 0%);
    border: 1px solid rgba(121, 50, 154, 0.33);
    border-radius: 4px;
    transition: box-shadow 200ms ease;
    height: 40px;
    box-shadow: initial;
    text-indent: 2px;

    &:hover {
      box-shadow: 0 0.25rem 1rem 0 rgba(121, 50, 154, 0.33);
      border: 1px solid rgba(121, 50, 154, 0.33);
      outline: none;
    }

    &:active,
    &:focus {
      box-shadow: 0 0.25rem 1rem 0 rgba(121, 50, 154, 0.66);
      border: 1px solid #79329a;
      outline: none;
    }

    &:invalid {
      border: 1px solid hsl(358, 75%, 49%);

      &:hover,
      &:focus {
        box-shadow: 0 0.25rem 1rem 0 hsl(356, 43%, 82%);
      }
    }
  }

  button:not(.tab):not(#forgot-password-button):not(#modal-close-button):not(
      .customButton
    ) {
    display: block;
    background-color: white;
    border-radius: clamp(0.5rem, 0.8vw, 0.75rem);
    border: 0.125rem solid transparent;
    color: #79329a;
    font-weight: 600;
    line-height: 1;
    padding: 0;
    cursor: pointer;
    box-shadow: 0 0.25rem 1rem 0 rgba(121, 50, 154, 0.33);
    transition: box-shadow 200ms ease;
    transition: border-color 200ms ease;
    height: 40px;
  }
}

.language-switcher-container {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  padding-left: clamp(1.5rem, 3vw, 2.5rem);
  padding-right: clamp(1.5rem, 3vw, 2.5rem);
  direction: ltr;

  .language-switcher {
    width: 100%;
    max-width: 1000px;
    padding-left: clamp(1.5rem, 3vw, 2.5rem);
    padding-right: clamp(1.5rem, 3vw, 2.5rem);

    // @media (min-width: 576px) {
    //   padding-top: 20px;
    //   padding-bottom: 20px;
    // }
  }
}

.language-holder {
  display: flex;
  flex-direction: row-reverse;
}

.language-button {
  background-color: transparent;
  color: #fff;
  border-color: transparent;
  padding: 10px 2px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  font-family: -apple-system, "system-ui", "Segoe UI", Roboto, Oxygen, Ubuntu,
    Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  border-radius: 8px;

  &:hover {
    box-shadow: 0 0.25rem 1rem 0 rgb(219 219 219 / 80%);
    border: 1px solid rgba(121, 50, 154, 0.33);
    outline: none;
  }

  &:active,
  &:focus {
    box-shadow: 0 0.25rem 1rem 0 rgb(219 219 219 / 80%);
    border: 2px solid #fff;
    outline: none;
  }

  &:invalid {
    border: 2px solid hsl(358, 75%, 49%);

    &:hover,
    &:focus {
      box-shadow: 0 0.25rem 1rem 0 hsl(356, 43%, 82%);
    }
  }
}

.language-button-dark {
  color: #79329a;

  &:hover {
    box-shadow: 0 0.25rem 1rem 0 rgba(121, 50, 154, 0.8);
    border: 1px solid rgba(121, 50, 154, 0.33);
    outline: none;
  }

  &:active,
  &:focus {
    box-shadow: 0 0.25rem 1rem 0 rgba(121, 50, 154, 0.8);
    border: 2px solid #79329a;
    outline: none;
  }

  &:invalid {
    border: 2px solid hsl(358, 75%, 49%);

    &:hover,
    &:focus {
      box-shadow: 0 0.25rem 1rem 0 hsl(356, 43%, 82%);
    }
  }
}

.back-button {
  margin: 0 !important;
  width: 70px;
}

.logo-text {
  color: #fff;
  font-size: 50px;
  margin: 0 !important;
}

.intro-text {
  max-width: 500px;
  font-size: 16px;
  line-height: 1.5;
  // margin-top: 2.5rem;
  margin: 25px 0 0;
  border-top: 0.063rem solid white;
  padding-top: 1rem;
  color: hsl(0, 100%, 100%);
  width: clamp(28.125rem, 30vw, 28.75rem);
}

.login-or-register {
  margin: 0 !important;
  padding: 0 !important;
  text-align: center;
  font-family: fantasy;
  font-weight: 600;
}

.user-section {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;
}

.user {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  font-size: 16px;
  font-weight: 600;
  padding: 0;

  @media (max-width: 600px) {
    flex-direction: column;
  }
}

.user-text {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
}

.main {
  background-color: #f5f5f5;
}

.responsive-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: 880px) {
    flex-direction: row;
    align-items: center;
  }
}

.header-container {
  max-width: 1000px;
  margin: 0 auto;
}

.invoice-table-container {
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  min-height: 100vh;
  max-width: 1000px;
  margin: 0 auto;
  // overflow-x: auto;
  // overflow-x: hidden;

  .search-bar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px 5px;
    margin-bottom: 20px;

    @media (min-width: 576px) {
      flex-direction: row;
      align-items: center;
    }

    .col {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    @media (min-width: 576px) {
      .button-col {
        margin-right: 10px;
      }
    }
    @media (min-width: 576px) {
      .title-col {
        margin-right: 10px;
      }
    }

    label {
      min-width: 55px;
      margin-right: 10px;
    }

    input,
    select,
    button {
      margin-right: 10px;
    }
  }

  .supplier-bar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px 5px;
    margin-bottom: 5px;

    @media (min-width: 576px) {
      flex-direction: row;
      align-items: center;
    }
  }

  .under-user-section {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .rtl {
    text-align: right;
  }

  .add-row-action-buttons {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: baseline;
  }

  .top-action-buttons {
    display: flex;
    // flex-direction: column-reverse;
    justify-content: space-between;
    @media (min-width: 576px) {
      flex-direction: row;
      align-items: center;
    }
    gap: 25px 10px;
    padding: 10px 10px 10px 0;
    align-items: flex-start;
    // justify-content: space-between;
    margin-bottom: 20px;
  }

  .button-group {
    display: flex;
    flex-direction: column;
    gap: 15px;

    @media (min-width: 576px) {
      flex-direction: row;
    }
  }

  .negative-value {
    color: red;
  }

  .positive-value {
    color: green;
  }

  .invoice {
    color: #008a00;
  }

  .container {
    color: #c60000;
  }

  .archived-invoice {
    color: #c60000;
  }

  .canceled-invoice td:not(.notes-cell) {
    text-decoration: line-through;
  }

  .canceled-invoice td {
    color: #03a9f4;
  }

  .canceled-invoice .invoice {
    color: #03a9f4;
  }

  .table-container {
    width: 100%;
    align-self: center;
    overflow-x: auto;
    padding: 10px 25px;
    border: 2px solid #e4e4e4;
    border-radius: 8px;
    margin: 0 2px;
    background-color: #fff;
  }

  .c-table .notes-cell-div {
    display: flex;
    flex-direction: column;
    color: #000;
    align-items: flex-start;
  }

  .c-table .notes-cell-div p {
    margin: 0;
  }

  .c-table .notes-cell-pair-container {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 3px;
  }

  .c-table .notes-bold {
    font-size: 12px;
    font-weight: 600;
  }

  .c-table .notes-modifiedDate {
    font-size: 12px;
  }

  .i-table {
    border-collapse: collapse;
  }

  .cell-title.invoiceNumber {
    width: 50%;
  }

  .i-table .notes-cell {
    width: 100%;
    max-width: 50px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .i-table .update-note {
    line-height: 1.1;
    font-weight: bold;
    font-size: 12px;
    color: #03a9f4;
    text-decoration: none !important
    ;
  }

  .i-table .table-head {
    border-bottom: 2px solid #ccc;
  }

  .i-table .table-head td {
    padding: 0 20px;
  }

  .i-table .invoiceNumber {
    border-bottom: 2px solid #79329a;
  }

  .i-table .table-body tr {
    border-radius: 16px;

    @media (max-width: 576px) {
      .invoice-number {
        border-start-end-radius: 8px;
        border-end-end-radius: 8px;
        width: 100%;
        // font-size: 200px;
      }
    }
  }

  .table-body tr:nth-child(even) td {
    background-color: #f5f5f5;
  }

  .rounded-first-cell {
    border-radius: 8px 0 0 8px;
  }

  .rounded-first-cell-ar {
    border-radius: 0 8px 8px 0;
  }

  .rounded-last-cell {
    border-radius: 0 8px 8px 0;
  }

  .rounded-last-cell-ar {
    border-radius: 8px 0 0 8px;
  }

  .sub-top-action-buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .action-buttons {
    margin-top: 20px;

    button {
      margin-right: 10px;
    }
  }

  .edit-row-action-buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
  }

  .cell-title {
    min-width: 110px;
    text-align: center;
    font-size: 14px;
  }

  .cell-title:dir(rtl) {
    font-family: "Tajawal", serif;
  }

  .cell {
    text-align: center;
    // height: 100px;
  }

  .cell .link {
    text-decoration: underline;
    color: #79329a;
  }

  .cell button {
    margin: auto;
  }

  .break-word {
    word-break: break-all;
  }

  .extra-padding-buttom {
    padding-bottom: 20px;
  }

  .hidden {
    display: none;
  }
}

.error-message {
  color: #cb5b52;
  margin-bottom: 10px;
  font-size: 12px;
}

.error-text {
  color: #cb5b52;
  font-size: 12px;
}

.bold-text {
  font-weight: 600;
}

@media (max-width: 992px) {
  .main-wrapper {
    width: 100%;
    flex-direction: column;
    margin-top: 40px;
    align-items: center;
    justify-content: flex-start;

    // &.register-form {
    //   height: 600px;
    // }

    &.contact-us-for-register-form {
      height: auto;
      justify-content: flex-start;
    }
  }

  .intro-text {
    display: none;
  }

  .website-logo {
    margin: 0 auto;
  }

  .rigister-login-form {
    overflow-y: unset;
    width: 100%;
    max-width: 600px;
  }

  .onboarding-register {
    height: unset;
  }
}

.text {
  margin: 0;
  padding: 0;
}

.contact-us-title-text {
  font-size: 20px;
  font-weight: 400;
}

.custom-select-box {
  background-color: transparent;
  min-width: 145px;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  font-size: 16px;
  color: #495057;
}

.custom-select-box:focus {
  outline: none;
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-select-box option {
  background-color: #f8f9fa;
  color: #495057;
}

/* Hover and selected option styles */
.custom-select-box option:hover,
.custom-select-box option:checked {
  background-color: #e9ecef;
  color: #212529;
}

.balances {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin: 20px 0 50px;
  border-radius: 4px;
}

.balances .balance {
  border: 1px solid #ccc;
  width: fit-content;
  padding: 10px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin: 0;

  @media (min-width: 576px) {
    flex-direction: row;
  }
}

.current-balance {
  border: 1px solid #ccc;
  width: fit-content;
  padding: 10px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-inline-start: auto;
  margin-bottom: 50px;
  border-radius: 4px;

  @media (min-width: 576px) {
    flex-direction: row;
  }
}

.full-w-button-container {
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  @media (min-width: 576px) {
    flex-direction: row-reverse;
  }
}

.pagination-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
  gap: 2px;
  width: 100%;

  @media (min-width: 576px) {
    gap: 20px;
    padding: 0 10px;
    width: auto;
  }
}

.pagination-location {
  font-weight: 600;
  color: #79329a;
  font-size: 14px;
  text-align: center;
  display: block;
  width: 100%;
}

// View suppliers and users and admins pages
.user-row-section {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.e-container {
  background-color: #f5f5f5;
  // width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  height: 100%;
  padding: 15px;
}

@media (min-width: 1000px) {
  .e-container {
    padding: 0;
  }
}

.view-users-page-container {
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  width: 100%;
  height: 100%;
  padding: 0px;
}

.users-list {
  height: 100%;
  background-color: #f5f5f5;
  padding: 1px;
}

.user-list-item {
  display: flex;
  flex-direction: column;
  gap: 1px;
  margin-bottom: 15px;
  transition: 0.3s;
}

.user-list-item:hover {
  background-color: #e1e1e1;
  padding: 10px;
  border-radius: 4px;
}

.user-list-item > p {
  margin: 0;
  padding: 0;
}

.user-username {
  font-size: 16px;
  font-weight: 600;
}

.edit-user-entity {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 15px;
}

.edit-user-actions {
  display: flex;
  gap: 10px;
  flex-direction: row-reverse;
}

.view-user-entity {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 20px;
  margin-bottom: 5px;
}

.view-user-entity > p {
  min-width: 140px;
  padding: 0;
  text-align: start;
}

.delete-user-actions {
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
  margin: 10px 0 60px;
  padding: 10px 0;

  @media print {
    display: none;
  }

  @media (min-width: 576px) {
    margin: 10px 0 10px;
  }
}

/* styles of date picker */
.react-datepicker {
  font-family: Arial, sans-serif; /* Change font */
  background-color: #f0f0f0; /* Change background color */
  border: 1px solid #ccc; /* Change border */
  border-radius: 5px; /* Rounded corners */
}

.react-datepicker__header {
  background-color: red; /* Header background */
  color: white; /* Header text color */
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: red; /* Selected day background */
  color: white; /* Selected day text color */
}

.react-datepicker__day:hover {
  background-color: red; /* Hover effect */
}

.react-datepicker__input-container input {
  border: 1px solid #ccc; /* Input border */
  border-radius: 5px; /* Input rounded corners */
  padding: 10px; /* Input padding */
  width: 100%; /* Full width */
}

// Control panel style
.control-panel-page {
  background-color: #f5f5f5;
  padding-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
}

.control-panel-container {
  width: 100%;
  max-width: 1000px;
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  padding: 15px 0;
}

.control-panel-actions {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 200px));
  gap: 20px;
  align-items: center;
}

.forgetPasswordTextButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
  border: none;
  font-size: 16px;
  margin-top: 10px;
  color: #79329a;
}

.user-page-title {
  font-size: 24px;
  font-weight: 500;
  margin-top: 25px;
}

// Add shipped container style
.add-shipped-container-page {
  padding: 20px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  max-width: 800px;
  overflow-y: hidden;
  margin: 0 auto;
}

.add-shipped-container-top-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.add-shipped-container-title {
  display: flex;
  flex-direction: row;
  font-size: 18px;
  margin: 20px 0 0 0;
}

.add-shipped-container-header {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 576px) {
    flex-direction: column;
  }
}

.add-shipped-container-header-section {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.add-shipped-container-invoice-part {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  padding-top: 10px;
  padding-inline-end: 20px;
}

.add-shipped-container-container-part {
  overflow-y: auto;
  padding: 0;
  padding-inline-end: 10px;
  background: /* Shadow covers */ linear-gradient(
      #f5f5f5 30%,
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(rgba(255, 255, 255, 0), #f5f5f5 70%) 0 100%,
    /* Shadows */
      radial-gradient(
        farthest-side at 50% 0,
        rgba(121, 50, 154, 0.66),
        rgba(121, 50, 154, 0)
      ),
    radial-gradient(
        farthest-side at 50% 100%,
        rgba(121, 50, 154, 0.66),
        rgba(121, 50, 154, 0)
      )
      0 100%;
  background-repeat: no-repeat;
  background-color: #f5f5f5;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;
}

.add-shipped-container-container-part::-webkit-scrollbar {
  width: 8px;
}

.add-shipped-container-container-part::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 6px;
  margin: 10px 0;
}

.add-shipped-container-container-part::-webkit-scrollbar-thumb {
  background: #79329a;
  border-radius: 6px;
  margin: 4px;
}

.add-shipped-container-next-previous {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
}

.add-shipped-container-add-another {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 10px 0 10px 0;
}

.shipped-container-card {
  
}

// View multi containers invoice 
.add-container-button-alignment {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 10px 0;
}

.all-containers {
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
  padding-inline-start: 20px;
  padding-top: 20px;
  gap: 15px;
  overflow-y: auto;
  padding-top: 20px;
  background: /* Shadow covers */ linear-gradient(
      #f5f5f5 30%,
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(rgba(255, 255, 255, 0), #f5f5f5 70%) 0 100%,
    /* Shadows */
      radial-gradient(
        farthest-side at 50% 0,
        rgba(121, 50, 154, 0.66),
        rgba(121, 50, 154, 0)
      ),
    radial-gradient(
        farthest-side at 50% 100%,
        rgba(121, 50, 154, 0.66),
        rgba(121, 50, 154, 0)
      )
      0 100%;
  background-repeat: no-repeat;
  background-color: #f5f5f5;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;
}

.all-containers::-webkit-scrollbar {
  width: 8px;
}

.all-containers::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 6px;
  margin: 10px 0;
}

.all-containers::-webkit-scrollbar-thumb {
  background: #79329a;
  border-radius: 6px;
  margin: 4px;
}