.confirmationBox {
    width: calc(100% - 10px);
    display: flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    text-align: center;
    margin: 20px auto;
    border: 1.5px solid #71b762;
    background-color: #71b76233;
    border-radius: 4px;
  
    /* @media (min-width: 576px) {
      width: 500px;
    } */
  }
  
  .confirmationBox .confirmationBoxText {
    margin: 0;
    color: #2a6438;
    font-size: 14px;
  }
  
  .confirmationBox img {
    width: 25px;
  }
  