.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modalContainer {
  margin-top: 2.5vh;
  border-radius: 8px;
  max-height: 95vh;
  max-width: 610px;
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 10px;

  @media (max-width: 576px) {
    position: fixed;
    top: 0;
    right: 0;
    border-radius: 0;
    height: 100vh;
    max-height: unset;
    width: 100%;
    margin: 0;
  }
}

.modal {
  max-width: 600px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  /* max-height: 95vh; */
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 40px 10px;
  padding-block: 20px;
}

.modalTitle {
  margin: 0;
  font-size: 18px;
}

.modalCloseIcon {
  outline: none;
  width: auto;
  padding: 5px;
  border: none;
  background-color: transparent;
  border-radius: 8px;

  &:hover {
    box-shadow: 0 0.25rem 1rem 0 rgba(121, 50, 154, 0.33);
    border: 1px solid rgba(121, 50, 154, 0.33);
    outline: none;
  }

  &:active,
  &:focus {
    box-shadow: 0 0.25rem 1rem 0 rgba(121, 50, 154, 0.66);
    border: 1px solid #79329a;
    outline: none;
  }

  &:invalid {
    border: 1px solid hsl(358, 75%, 49%);

    &:hover,
    &:focus {
      box-shadow: 0 0.25rem 1rem 0 hsl(356, 43%, 82%);
    }
  }
}

.closeIcon {
  width: 14px;
  cursor: pointer;
}

.modalSubHeader {
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
}

.modalSubHeaderDivider {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
}

.modalSubHeaderDividerFirstDiv {
  white-space: nowrap;
  font-weight: 500;
}

.modalSubHeaderDividerSecondDiv {
  background-color: #eee;
  height: 2px;
  width: 100%;
}

.flexableFormWithPadding {
  padding-top: 10px;
}

.modalBody {
  padding: 0 40px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
  box-sizing: border-box;
  margin-bottom: 20px;
  background: /* Shadow covers */ linear-gradient(
      white 30%,
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    /* Shadows */
      radial-gradient(
        farthest-side at 50% 0,
        rgba(121, 50, 154, 0.66),
        rgba(121, 50, 154, 0)
      ),
    radial-gradient(
        farthest-side at 50% 100%,
        rgba(121, 50, 154, 0.66),
        rgba(121, 50, 154, 0)
      )
      0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;
}

.modalBody::-webkit-scrollbar {
  width: 8px;
}

.modalBody::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 6px;
  margin: 10px 0;
}

.modalBody::-webkit-scrollbar-thumb {
  background: #79329a;
  border-radius: 6px;
  margin: 4px;
}

.withDropdown {
  overflow: unset;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.flexableForm {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
}

.inputContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 5px;
  flex: 1;

  @media (min-width: 576px) {
    flex-direction: row;
  }
}

.inputContainer .longSpan {
  font-size: 14px;
  min-width: 160px;
  display: flex;
  align-items: center;
}

.inputContainer .notesSpan {
  align-items: flex-start;
}

.inputContainer span {
  font-size: 14px;
  min-width: 150px;
  display: flex;
  align-items: center;
}

.notesClass {
  font-size: 14px;
  min-width: 150px;
  display: flex;
  align-items: flex-start !important;
}

.inputContainer .shortSpan {
  min-width: 120px;
}

.datePicker {
  color: #000;
  height: unset;
  padding: 5px 10px;
  width: 100%;
  border: 1px solid #92929280;
  border-radius: 4px;
  box-shadow: initial;
  text-indent: 2px;
  background-color: #fff;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.datePicker:focus,
.datePicker:hover {
  border-radius: 4px;
}

.error {
  display: flex;
  justify-content: flex-end;
  padding: 0px 40px;
}

.beforeFooterContainer {
  width: 100%;
  border-top: 1px solid #eee;
  padding-top: 10px;
}

.beforeFooterChildren {
  padding: 0 20px 5px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.beforeFooterNextPrevious {
  padding: 0 20px 10px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.modalFooter {
  padding: 0 40px 20px;
}

.modalBoxShadow {
  box-shadow: 1px -12px 13px 0px rgb(211 182 224 / 33%);
}

.actions {
  display: flex;
  gap: 10px;
  flex-direction: row-reverse;
}

.backIcon {
  position: absolute;
  top: 16px;
  left: 16px;
  width: 14px;
  cursor: pointer;
}

.buttonContainer {
  display: flex;
  gap: 5px;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: 576px) {
    flex-direction: row;
    align-items: center;
  }
}

.buttonContainer span {
  font-size: 14px;
  min-width: 120px;
  display: flex;
  align-items: center;
}

.customDropDown {
  flex: 1;
}

.invoiceNumberContainer {
  width: 100%;
}

.infoUpdatedIn {
  color: #03a9f4;
  font-size: 14px;
}

/* .loaderHolder {
  background-color: yellow;
} */
