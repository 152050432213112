.customButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0 20px;
  border-radius: 8px;
  border: 0.125rem solid transparent;

  font-weight: 600;
  line-height: 1;
  cursor: pointer;
  box-shadow: 0 0.25rem 1rem 0 rgba(121, 50, 154, 0.33);
  transition: 200ms;
  height: 40px;
  text-decoration: none;
  font-size: 14px;
  /* min-width: 100px; */
  background-color: #79329a;
  color: #fff;
  max-width: 220px;
  @media print {
    display: none;
  }

  &:hover {
    box-shadow: 0 0.25rem 1rem 0 rgba(121, 50, 154, 0.8);
    border: 1px solid;
    outline: none;
  }

  &:active,
  &:focus {
    box-shadow: 0 0.25rem 1rem 0 rgba(121, 50, 154, 0.8);
    border: 2px solid;
    outline: none;
  }

  &:invalid {
    border: 2px solid hsl(358, 75%, 49%);

    &:hover,
    &:focus {
      box-shadow: 0 0.25rem 1rem 0 hsl(356, 43%, 82%);
    }
  }
}

.title {
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
}

.blank {
  color: #79329a;
  background-color: #fff;
}

.blank-fit-content {
  color: #79329a;
  background-color: #fff;
  width: fit-content;
}

.gray {
  background-color: #e0e0e0;
  color: #000;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.red {
  background-color: #c60000;
}

.green {
  background-color: #71b762;
}

.icon {
  width: 24px;
}
