.InformationBox {
  width: calc(100% - 10px);
  display: flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  text-align: center;
  margin: 20px auto;
  border: 1.5px solid #426f8c;
  background-color: #ddecf6;
  border-radius: 4px;

  @media (min-width: 576px) {
    width: 500px;
    max-width: 100%;
  }
}

.InformationBox p {
  margin: 0;
  color: #426f8c;
  font-size: 14px;
}

.InformationBox img {
  width: 17px;
}
