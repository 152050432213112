.errorBox {
    display: flex;
    width: 100%;
    padding: 10px 20px;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    border-radius: 4px;
    border: 1.5px solid #cb5b52;
    background-color: #fff;
    margin: 20px auto;
  }
  
  .errorBox .errorBoxText {
    margin: 0;
    color: #cb5b52;
    font-size: 14px;
  }
  
  .errorBox img {
    width: 25px;
  }
  