.mainDiv {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.iframe {
  width: 200px;
  height: 200px;
  border: none;
  overflow: hidden;
}

.iframe.fullHeightIframe {
  width: 100%;
  height: 2000px;
  /* min-height: 100vh; */
}
