.inputLabel {
  font-size: 13px;
  padding-bottom: 10px;
}

.right {
  direction: rtl;
}

.left {
  direction: ltr;
}
