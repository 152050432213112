/* ChartModal.scss */

.chart-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    overflow: auto;
  
    display: flex;
    flex-direction: column;
    align-items: center;

   
    border-radius: 8px;
    padding: 10px;

    width: 100%;
    max-width: 600px;

    @media only screen and (max-width: 400px) {
      max-height: 90%;
      max-width: 360px;
    }
  }
  
  .chart-modal-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 10px;
    padding-block: 20px;
  }
  
  .chart-modal-close-button {
    outline: none;
    width: auto;
    padding: 5px;
    border: none;
    background-color: transparent;
    border-radius: 8px;

    &:hover {
      box-shadow: 0 0.25rem 1rem 0 rgba(121, 50, 154, 0.33);
      border: 1px solid rgba(121, 50, 154, 0.33);
      outline: none;
    }
  
    &:active,
    &:focus {
      box-shadow: 0 0.25rem 1rem 0 rgba(121, 50, 154, 0.66);
      border: 1px solid #79329a;
      outline: none;
    }
  
    &:invalid {
      border: 1px solid hsl(358, 75%, 49%);
  
      &:hover,
      &:focus {
        box-shadow: 0 0.25rem 1rem 0 hsl(356, 43%, 82%);
      }
    }
  }

  .chart-modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .chart-holder {
    display: flex;
    flex-direction: column;
  }

  #myChart {
    @media only screen and (max-width: 400px) {
        max-width: 300px;
        max-height: 300px;
        margin: 20px;
    }
  }

  .chart-pagination-controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 20px;
  }

  /* Add styles for the close button */
  .chart-modal button {
    margin-top: 20px;
  
    /* Responsive styles for the button */
    @media only screen and (max-width: 600px) {
      margin-top: 10px;
    }
  }
